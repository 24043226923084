<template>
    <b-container fluid="xl" class="pl-4 pl-md-4 pl-lg-5 pl-xl-0 pr-2" v-if="info">
        <b-row class="mr-3 mr-xl-0">
            <b-col cols="12">
                <b-row>
                    <b-col class="mt-5 content">
                        <h3>{{ title }}</h3>
                    </b-col>
                    <b-col cols="6" class="d-block text-center mt-2">
                        <img :src="info.image_description.path" v-if="info.image_description" class="image_description">
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Description",
        data() {
            return {
                img_path: process.env.VUE_APP_IMG,
                types: ["", "Художественная направленность", "Социально-гуманитарная направленность", "Техническая направленность","Физкультурно-спортивная направленность", "Естественнонаучная направленность"]
            }
        },
        computed: {
            info: function () {
                return this.$store.getters.allPages;
            },
            description: function(){
                return this.info.description.split("<hr>");
            },
            getTheme() {
                return this.$store.getters.userTheme
            },
            title(){
                return this.types[this.$route.query.id];
            }
        }
    }
</script>

<style scoped>
    .title {
        width: 600px;
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: var(--text-primary-color);
    }

    .content {
        padding-right: 32px;
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        color: var(--text-secondary-color);
    }

    h4 {
        font-family: SF BOLD, sans-serif;
        font-weight: 400;
        font-style: normal;
        text-align: center;
        font-size: 16px !important;
        line-height: 18px !important;
        color: var(--text-secondary-color) !important;
    }

    .image_description {
        width: 100%;
        max-width: 460px;
    }

</style>